import { Component, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { TimevizComponent } from './timeviz/timeviz.component';

@Component({
  selector: 'app-timeviz-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(injector: Injector) {
    const TimevizElement = createCustomElement(TimevizComponent, { injector });
    customElements.define('timeviz-element', TimevizElement);
  }
}
