import { Component, Input } from '@angular/core';
import { PlotlyService, PlotlySharedModule } from 'angular-plotly.js';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import * as Plotly from 'plotly.js';
import { HttpClient } from '@angular/common/http';
import { NgIf } from '@angular/common';

interface Unit {
  value: string;
  viewValue: string;
}

interface DataOptions {
  mission: string;
  id: string;
  loaded: boolean;
  selectedFluxUnit: string;
  selectedTimeUnit: string;
  selectedTimeScale: string;
  isTimeView: boolean;
  dataIndexStart: number;
  dataIndexEnd: number;
  data: [Partial<Plotly.PlotData>] | undefined;
  alt_time_units: string[];
  alt_flux_units: string[];
}

interface TimeVizBackendOptions {
  mission: string;
  url: string;
  defaultFluxUnit: string;
  defaultTimeUnit: string;
  defaultTimeScale: string;
}

@Component({
  selector: 'app-timeviz',
  standalone: true,
  imports: [
    PlotlySharedModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    FormsModule,
    MatProgressSpinnerModule,
    NgIf
  ],
  templateUrl: './timeviz.component.html',
  styleUrl: './timeviz.component.scss'
})
export class TimevizComponent {
  private plotly: any;
  private divId: string = 'timeviz-plotly';
  private resizeTimeout: any;
  isLoading: boolean = false;
  isTimeView = true;
  private isCombinedMissionsView = false;
  private numberOfDataCurrentlyLoading: number = 0;
  private currentData = new Map<string, DataOptions>();
  private unitsHaveBeenChangedByUser: boolean = false;
  private selectedTimeScale: string = '';

  fluxElectronPerSecond: Unit = { value: 'electron/s', viewValue: 'Electron / s' };
  xmmFluxCts: Unit = { value: 'ct/s', viewValue: 'ct / s' };

  fluxUnits: Unit[] = [
    { value: 'Jy', viewValue: 'Jy' },
    { value: 'mJy', viewValue: 'mJy' }
  ];

  timeUnits: Unit[] = [
    { value: 'jd', viewValue: 'JD' },
    { value: 'mjd', viewValue: 'MJD' }
  ];

  private sourceUrls = new Map<string, TimeVizBackendOptions>([
    [
      'Gaia-DR3',
      {
        mission: 'Gaia-DR3',
        //url: 'http://localhost:5000/ts/v1?mission=gaia&sourceID={id}&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        url: '/tsview/ts/v1?mission=gaia&sourceID={id}&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        defaultFluxUnit: this.fluxElectronPerSecond.value,
        defaultTimeUnit: this.timeUnits[0].value,
        defaultTimeScale: 'tcb'
      }
    ],
    [
      'JWST-MID-IR',
      {
        mission: 'JWST-MID-IR',
        //url: 'http://localhost:5000/ts/v1?mission=jwst&obsID={id}&prodType=x1dints&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        url: '/tsview/ts/v1?mission=jwst&obsID={id}&prodType=x1dints&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        defaultFluxUnit: this.fluxUnits[0].value,
        defaultTimeUnit: this.timeUnits[0].value,
        defaultTimeScale: 'tdb'
      }
    ],
    [
      'XMM-EPIC',
      {
        mission: 'XMM-EPIC',
        //url: 'http://localhost:5000/ts/v1?mission=xmm-epic&sourceID={id}&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        url: '/tsview/ts/v1?mission=xmm-epic&sourceID={id}&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        defaultFluxUnit: this.xmmFluxCts.value,
        defaultTimeUnit: this.timeUnits[0].value,
        defaultTimeScale: 'tt'
      }
    ],
    [
      'CHEOPS',
      {
        mission: 'CHEOPS',
        //url: 'http://localhost:5000/ts/v1?mission=cheops&sourceID={id}&product_url={product_url}&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        url: '/tsview/ts/v1?mission=cheops&sourceID={id}&product_url={product_url}&target_time_unit={selectedTimeUnit}&target_flux_unit={selectedFluxUnit}&timeView={isTimeView}&target_time_scale={selectedTimeScale}',
        defaultFluxUnit: this.fluxElectronPerSecond.value,
        defaultTimeUnit: this.timeUnits[0].value,
        defaultTimeScale: 'tt'
      }
    ]
  ]);

  selectedFluxUnit: string = this.fluxUnits[0].value;

  fluxUnitChanged(newUnit: string) {
    this.selectedFluxUnit = newUnit;
    this.unitsHaveBeenChangedByUser = true;
    if (this.layout.yaxis != undefined) {
      this.layout.yaxis.range = [undefined, undefined];
    }
    this.fetchDataWithNewUnits();
  }

  fetchDataWithNewUnits() {
    const dataToFetch = new Map<string, DataOptions>();
    this.currentData.forEach((dataOptions, key) => {
      if (
        dataOptions.selectedFluxUnit !== this.selectedFluxUnit ||
        dataOptions.selectedTimeUnit != this.selectedTimeUnit ||
        dataOptions.selectedTimeScale != this.selectedTimeScale ||
        dataOptions.isTimeView != this.isTimeView
      ) {
        if (!dataOptions.loaded) {
          this.lowerNumberOfDataCurrentlyLoading();
        } else {
          if (dataOptions.dataIndexStart == 0 && this.data.length - 1 == dataOptions.dataIndexEnd) {
            // plotly fails to remove the last series unless the data is set to a new empty array
            this.data = [];
          } else {
            this.data.splice(dataOptions.dataIndexStart, dataOptions.dataIndexEnd - dataOptions.dataIndexStart + 1);
          }
          this.updateIndices(dataOptions.dataIndexStart, dataOptions.dataIndexEnd);
        }
        this.currentData.delete(key);
        dataToFetch.set(key, dataOptions);
      }
    });
    dataToFetch.forEach((value) => {
      this.fetchData(value.mission, value.id);
    });
  }

  updateIndices(startIndex: number, endIndex: number) {
    this.currentData.forEach((dataOption: DataOptions) => {
      if (dataOption.dataIndexStart > startIndex) {
        dataOption.dataIndexStart -= endIndex - startIndex + 1;
      }
      if (dataOption.dataIndexEnd > startIndex) {
        dataOption.dataIndexEnd -= endIndex - startIndex + 1;
      }
    });
  }

  selectedTimeUnit: string = this.timeUnits[0].value;

  timeUnitChanged(newUnit: string) {
    this.selectedTimeUnit = newUnit;
    if (this.layout.xaxis != undefined) {
      this.layout.xaxis.type = this.selectedTimeUnit === 'iso' && this.isTimeView ? 'date' : 'linear';
    }
    this.unitsHaveBeenChangedByUser = true;
    this.fetchDataWithNewUnits();
  }

  resetIcon = {
    width: 620,
    height: 530,
    svg: [
      '<?xml version="1.0" encoding="UTF-8"?>\n' +
        '<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 653.7 640.19">\n' +
        '  <defs>\n' +
        '    <style>\n' +
        '      .cls-1 {\n' +
        '        fill: none;\n' +
        '        stroke-width: 83px;\n' +
        '      }\n' +
        '\n' +
        '      .cls-1, .cls-2 {\n' +
        '        stroke: #efefef;\n' +
        '        stroke-miterlimit: 10;\n' +
        '      }\n' +
        '\n' +
        '      .cls-2 {\n' +
        '        fill: #efefef;\n' +
        '        stroke-width: 55px;\n' +
        '      }\n' +
        '    </style>\n' +
        '  </defs>\n' +
        '  <path class="cls-1" d="M239.5,70C134.49,106.13,66.82,216.23,84.18,333.13c19.38,130.54,137.46,220.12,263.74,200.08,126.28-20.04,212.93-142.11,193.55-272.65-7.47-50.32-29.61-94.55-61.23-128.77"/>\n' +
        '  <polygon class="cls-2" points="530.73 86.42 398.13 77.63 421.76 212.8 530.73 86.42"/>\n' +
        '</svg>'
    ].join('')
  };
  data: any = [];
  config: Partial<Plotly.Config> = {
    displaylogo: false,
    modeBarButtonsToAdd: [
      {
        name: 'reset axes',
        title: 'Reset axes',
        icon: this.resetIcon,
        click: () => {
          if (this.layout.yaxis && this.layout.xaxis) {
            this.layout.yaxis.autorange = true;
            this.layout.xaxis.autorange = true;
            this.plotly?.redraw(this.divId);
          }
        }
      }
    ]
  };

  layout: Partial<Plotly.Layout> = {
    autosize: true,
    showlegend: true,
    margin: {
      l: 80,
      r: 0,
      b: 80,
      t: 70,
      pad: 1
    },
    colorway: [
      '#4fa7e4',
      '#ffaf3e',
      '#5cd05c',
      '#f65758',
      '#c497ed',
      '#bc867b',
      '#f3a7f2',
      '#afafaf',
      '#eced52',
      '#47eeff'
    ],

    paper_bgcolor: '#000000',
    plot_bgcolor: '#000000',
    hoverlabel: {
      bgcolor: '#FFFFFF',
      font: {
        color: '#000000'
      }
    },
    xaxis: {
      title: 'Time',
      showline: false,
      autorange: true,
      zeroline: false,
      titlefont: {
        family: 'Arial, sans-serif',
        size: 12
      },
      color: '#FFFFFF'
    },
    yaxis: {
      title: 'Flux',
      autorange: false,
      titlefont: {
        family: 'Arial, sans-serif',
        size: 12
      },
      exponentformat: 'power',
      showline: false,
      zeroline: false,
      color: '#FFFFFF',
      range: [undefined, undefined]
    },
    title: {
      text: '',
      font: {
        size: 14,
        color: '#FFFFFF'
      }
    },

    legend: {
      font: {
        color: '#EEEEEE'
      },
      groupclick: 'toggleitem'
    },
    modebar: {
      bgcolor: '#000000',
      color: '#DDDDDD',
      activecolor: '#FFFFFF',
      remove: ['autoScale2d', 'lasso2d', 'select2d', 'toImage', 'zoomIn2d', 'zoomOut2d', 'resetScale2d']
    }
  };

  /*
  Using Angular Elements to create timeviz element. This is a workaround to be able to use the component in
  non-angular applications. To create and append timeviz to your application you need to do something like this:
    const timevizElement = document.createElement('timeviz-element')
    document.body.appendChild(timevizElement);

  The @Input fields are the API and you can add data by setting the addData attribute like this:
		timevizElement.addData = ['Gaia-DR3, ''Gaia+DR3+4111834567779557376'];
   */
  @Input()
  set addData(dataInfo: string[]) {
    if (dataInfo.length === 0) {
      return;
    }
    const mission = dataInfo[0];
    const id = dataInfo[1];
    const productUrl = dataInfo[2];
    if (mission.toLowerCase().includes('gaia-dr3')) {
      this.fetchData('Gaia-DR3', id);
    } else if (mission.toLowerCase().includes('jwst-mid-ir')) {
      this.fetchData('JWST-MID-IR', id);
    } else if (mission.toLowerCase().includes('xmm-epic')) {
      this.fetchData('XMM-EPIC', id);
    } else if (mission.toLowerCase().includes('cheops')) {
      this.fetchData('CHEOPS', id, productUrl);
    }
  }

  addResizeObserver() {
    const observer: ResizeObserver = new ResizeObserver(() => {
      clearTimeout(this.resizeTimeout);
      this.resizeTimeout = setTimeout(() => {
        this.updatePlotlySize(plotlyPlot);
      }, 400);
    });
    const plotlyPlot = document.getElementById(this.divId);
    if (plotlyPlot != null) {
      observer.observe(plotlyPlot);
    }
  }

  private updatePlotlySize(plotlyPlot: HTMLElement | null) {
    if (plotlyPlot != null) {
      this.plotly.update(this.divId, {}, { height: plotlyPlot.clientHeight, width: plotlyPlot.clientWidth });
    }
  }

  constructor(private plotlyService: PlotlyService, private http: HttpClient) {
    plotlyService.getPlotly().then((plotly) => {
      this.plotly = plotly;
      this.addResizeObserver();
    });
    this.data = [];
  }

  isNumbers(array: Plotly.Datum[] | Plotly.TypedArray | Plotly.Datum[][] | undefined): array is number[] {
    // Casting array to number. Checking more than index 0 to deal with potential null values.
    return (
      array !== undefined &&
      (typeof array[0] === 'number' ||
        typeof array[array.length - 1] === 'number' ||
        typeof array[Math.max(1, Math.floor(array.length / 2)) - 1] === 'number')
    );
  }

  populateUrlParameters(baseUrl: string, id: string, productUrl?: string | null): string {
    productUrl = productUrl || '';
    return baseUrl
      .replace('{id}', id)
      .replace('{product_url}', productUrl)
      .replace('{selectedTimeUnit}', this.selectedTimeUnit)
      .replace('{selectedFluxUnit}', this.selectedFluxUnit)
      .replace('{selectedTimeScale}', this.selectedTimeScale)
      .replace('{isTimeView}', this.isTimeView.toString());
  }

  setDefaultUnits(mission: string) {
    this.selectedTimeUnit = this.sourceUrls.get(mission)?.defaultTimeUnit || this.selectedTimeUnit;
    if ((mission === 'Gaia-DR3' || mission === 'CHEOPS') && this.fluxUnits.indexOf(this.fluxElectronPerSecond) === -1) {
      this.fluxUnits.push(this.fluxElectronPerSecond);
    }
    if (mission === 'XMM-EPIC' && this.fluxUnits.indexOf(this.xmmFluxCts) === -1) {
      this.fluxUnits.push(this.xmmFluxCts);
    }
    this.selectedFluxUnit = this.sourceUrls.get(mission)?.defaultFluxUnit || this.selectedFluxUnit;
  }
  fetchData(mission: string, id: string, productUrl?: string | null): void {
    const missionOptions = this.sourceUrls.get(mission);
    if (missionOptions === undefined) {
      console.warn('No url found for mission: ' + mission);
      return;
    }
    const wasCombinedMissionsView = this.isCombinedMissionsView;
    this.isCombinedMissionsView = false;
    if (this.currentData.size > 0) {
      this.currentData.forEach((value) => {
        if (value.mission !== mission) {
          this.isCombinedMissionsView = true;
        }
      });
    }
    if (
      this.isCombinedMissionsView &&
      !wasCombinedMissionsView &&
      (!this.unitsHaveBeenChangedByUser ||
        this.selectedFluxUnit === this.fluxElectronPerSecond.value ||
        this.selectedFluxUnit === this.xmmFluxCts.value ||
        this.selectedFluxUnit.toLowerCase().includes('mag'))
    ) {
      this.selectedFluxUnit = this.fluxUnits[1].value;
      const electronPerSecondIndex = this.fluxUnits.indexOf(this.fluxElectronPerSecond);
      if (electronPerSecondIndex !== -1) {
        this.fluxUnits.splice(electronPerSecondIndex, 1);
      }
      const xmmFluxCtsIndex = this.fluxUnits.indexOf(this.xmmFluxCts);
      if (xmmFluxCtsIndex !== -1) {
        this.fluxUnits.splice(xmmFluxCtsIndex, 1);
      }
      this.fluxUnits = this.fluxUnits.filter((unit) => !unit.value.toLowerCase().includes('mag'));
    } else if (this.currentData.size == 0 && !this.unitsHaveBeenChangedByUser) {
      this.setDefaultUnits(mission);
    }
    if (this.selectedTimeScale === '') {
      this.selectedTimeScale = this.sourceUrls.get(mission)?.defaultTimeScale || this.selectedTimeScale;
    }
    const urlWithParameters = this.populateUrlParameters(missionOptions.url, id, productUrl);
    if (this.currentData.has(urlWithParameters)) {
      console.warn('Data is already loading for ' + mission + ' with id ' + id);
      return;
    }
    this.currentData.set(urlWithParameters, {
      mission: mission,
      id: id,
      loaded: false,
      selectedTimeUnit: this.selectedTimeUnit,
      selectedFluxUnit: this.selectedFluxUnit,
      selectedTimeScale: this.selectedTimeScale,
      isTimeView: this.isTimeView,
      dataIndexStart: 0,
      dataIndexEnd: 0,
      alt_time_units: [],
      alt_flux_units: [],
      data: undefined
    });
    if (this.isCombinedMissionsView && !wasCombinedMissionsView) {
      this.fetchDataWithNewUnits();
    }
    this.isLoading = true;
    this.numberOfDataCurrentlyLoading++;
    this.http
      .get<{
        data: [Partial<Plotly.PlotData>];
        layout: Partial<Plotly.Layout>;
        alt_time_units: string[];
        alt_flux_units: string[];
      }>(urlWithParameters)
      .subscribe({
        next: (response) => {
          if (this.currentData.has(urlWithParameters)) {
            this.lowerNumberOfDataCurrentlyLoading();
            const dataOption = this.currentData.get(urlWithParameters);
            if (dataOption == undefined) {
              console.error('No data option found for ' + urlWithParameters);
              return;
            }
            dataOption.data = response.data;
            dataOption.data.forEach((data) => {
              data.legendgroup = dataOption.id;
              data.legendgrouptitle = { text: dataOption.id, font: { color: '#BBBBBB' } };
            });
            dataOption.alt_time_units = response.alt_time_units;
            dataOption.alt_flux_units = response.alt_flux_units;
            this.addAlternateUnits(dataOption.alt_time_units, dataOption.alt_flux_units);
            this.readResponse(response.layout, urlWithParameters);
          }
        },
        error: (error) => {
          this.lowerNumberOfDataCurrentlyLoading();
          console.error('Error fetching data for ' + mission + ' with id ' + id + '. Error: ' + error);
          const dataOptions = this.currentData.get(urlWithParameters);
          if (dataOptions !== undefined) {
            dataOptions.loaded = true;
          }
        }
      });
  }

  private addAlternateUnits(alt_time_units: string[], alt_flux_units: string[]): void {
    for (let i = 0; i < alt_time_units.length; i++) {
      if (!this.timeUnits.some((unit) => unit.value === alt_time_units[i])) {
        this.timeUnits.push({ value: alt_time_units[i], viewValue: alt_time_units[i].toUpperCase().replace('_', ' ') });
      }
    }
    for (let i = 0; i < alt_flux_units.length; i++) {
      if (
        !this.fluxUnits.some((unit) => unit.value === alt_flux_units[i]) &&
        !((this.isCombinedMissionsView || !this.isTimeView) && alt_flux_units[i].toLowerCase().includes('mag'))
      ) {
        this.fluxUnits.push({ value: alt_flux_units[i], viewValue: alt_flux_units[i].replace('_', ' ') });
      }
    }
  }
  private lowerNumberOfDataCurrentlyLoading() {
    this.numberOfDataCurrentlyLoading--;
    if (this.numberOfDataCurrentlyLoading === 0) {
      this.isLoading = false;
    }
  }

  private readResponse(layout: Partial<Plotly.Layout>, urlWithParameters: string): void {
    if (layout.yaxis && this.layout.yaxis) {
      this.layout.yaxis.autorange = layout.yaxis.autorange;
    }
    this.animateInDataArray(urlWithParameters);
    if (this.layout.xaxis && this.layout.yaxis) {
      if (layout.xaxis && layout.yaxis) {
        this.layout.xaxis.title = layout.xaxis.title;
        this.layout.yaxis.title = layout.yaxis.title;
        if (layout.yaxis.autorange) {
          this.layout.yaxis.autorange = layout.yaxis.autorange;
        }
      }
      this.layout.xaxis.autorange = true;
    }
  }

  private isAnimating: boolean = false;
  private animationQueue: string[] = [];
  private animateInDataArray(urlWithParameters: string): void {
    if (this.isAnimating) {
      this.animationQueue.push(urlWithParameters);
      return;
    }
    let newDataArray: [Partial<Plotly.PlotData>] = this.currentData.get(urlWithParameters)?.data || [{}];
    let tooBigToAnimate: boolean = false;
    newDataArray.forEach((newData) => {
      if (newData.y !== undefined && newData.y.length > 10000) {
        tooBigToAnimate = true;
      }
    });
    this.isAnimating = true;
    this.plotly?.deleteFrames(this.divId);
    let { min, max } = this.getYaxisMaxMin(newDataArray);
    const savedValues: any[] = [];
    newDataArray = this.data.concat(newDataArray);
    if (!tooBigToAnimate) {
      // To force plotly to animate in data, we need to set the y values to 0 and then animate to the new values
      newDataArray.forEach((newData) => {
        savedValues.push({ ...newData });
        if (newData.y === undefined) {
          newData.y = [];
        } else {
          newData.y = new Array<number>(newData.y.length).fill(0);
        }
      });
    }
    const storedAutoRange = this.layout.yaxis?.autorange;

    let changedZoom: boolean = false;
    if (this.layout.yaxis && this.layout.yaxis.range) {
      let previousMin: number = Number.MAX_VALUE;
      let previousMax: number = Number.MIN_VALUE;
      if (typeof this.layout.yaxis.range[0] === 'number' && typeof this.layout.yaxis.range[1] === 'number') {
        previousMin = Math.min(this.layout.yaxis.range[0], this.layout.yaxis.range[1]);
        previousMax = Math.max(this.layout.yaxis.range[0], this.layout.yaxis.range[1]);
      }

      if (this.data.length !== 0) {
        min = Math.min(min, previousMin);
        max = Math.max(max, previousMax);
      }

      changedZoom = min != previousMin || max != previousMax;
      if (changedZoom) {
        max = max + (max - min) * 0.03;
        min = min - (max - min) * 0.03;
      }
      console.log('min: ' + min + ' max: ' + max + ' previousMin: ' + previousMin + ' previousMax: ' + previousMax);

      console.log('changedZoom: ' + changedZoom);
      const range: number[] = storedAutoRange === 'reversed' ? [max, min] : [min, max];
      const zoomDuration: number = 300;

      // As of 2024-03-04, the plotly.js library does not support animating both layout and data at the same time,
      // not even if they are in different frames. Thus, we need to animate the layout first and then the data.
      this.plotly?.addFrames(this.divId, [
        {
          data: savedValues,
          name: 'allData'
        }
      ]);
      if (tooBigToAnimate) {
        if (changedZoom) {
          this.layout.yaxis.autorange = storedAutoRange;
          this.layout.yaxis.range = range;
        }
        this.data = newDataArray;
        this.setDataIndices(urlWithParameters, newDataArray.length);
        this.isAnimating = false;
        return;
      }
      if (changedZoom) {
        this.plotly?.addFrames(this.divId, [
          {
            layout: {
              yaxis: { range: range, autorange: storedAutoRange }
            },
            name: 'zoom'
          }
        ]);
        this.plotly?.animate(this.divId, ['zoom'], {
          frame: [{ duration: zoomDuration }],
          transition: [{ duration: zoomDuration, easing: 'cubic-in-out' }],
          mode: 'afterall'
        });
        setTimeout(() => {
          this.animateFromZero(newDataArray, storedAutoRange, urlWithParameters);
        }, zoomDuration);
      } else {
        this.animateFromZero(newDataArray, storedAutoRange, urlWithParameters);
      }
    }
  }

  private animateFromZero(
    dataWithZeroValues: [Partial<Plotly.PlotData>],
    storedAutoRange: 'reversed' | undefined | boolean,
    urlWithParameters: string
  ): void {
    if (this.layout.yaxis) {
      this.layout.yaxis.autorange = false;
    }
    this.setDataIndices(urlWithParameters, dataWithZeroValues.length);
    this.data = dataWithZeroValues;
    setTimeout(() => {
      this.animateInData();
      if (this.layout.yaxis) {
        this.layout.yaxis.autorange = storedAutoRange;
      }
    }, 10);
  }

  private setDataIndices(urlWithParameters: string, dataLength: number) {
    const dataOptions = this.currentData.get(urlWithParameters);
    if (dataOptions === undefined) {
      console.error('No data options found for ' + urlWithParameters);
    } else {
      dataOptions.loaded = true;
      dataOptions.dataIndexStart = this.data.length;
      dataOptions.dataIndexEnd = this.data.length + dataLength - 1;
    }
  }

  private animateInData(): void {
    this.plotly?.animate(this.divId, ['allData'], {
      frame: [{ duration: 1000 }],
      transition: [{ duration: 1000, easing: 'cubic-out' }],
      mode: 'afterall'
    });
    setTimeout(() => {
      this.isAnimating = false;
      if (this.animationQueue.length > 0) {
        const urlWithParameters = this.animationQueue.shift();
        if (urlWithParameters === undefined) {
          console.error('urlWithParameters undefined');
          return;
        }

        this.animateInDataArray(urlWithParameters);
      }
    }, 1200);
  }

  private getYaxisMaxMin(plotData: [Partial<Plotly.PlotData>]): {
    min: number;
    max: number;
  } {
    let min: number = Number.MAX_VALUE;
    let max: number = Number.MIN_VALUE;
    plotData.forEach((series: Partial<Plotly.PlotData>) => {
      if (this.isNumbers(series.y)) {
        let maxError: number = 0;
        if (series.error_y !== undefined && 'array' in series.error_y && this.isNumbers(series.error_y.array)) {
          maxError = Math.max(...series.error_y.array);
        }
        const newMin: number = Math.min(...series.y.filter((value): value is number => value !== null)) - maxError;
        if (newMin < min) {
          min = newMin;
        }
        const newMax: number = Math.max(...series.y) + maxError;
        if (newMax > max) {
          max = newMax;
        }
      }
    });
    return { min, max };
  }

  switchWavelengthTimeView(): void {
    this.isTimeView = !this.isTimeView;
    if (!this.isTimeView) {
      this.fluxUnits = this.fluxUnits.filter((unit) => !unit.value.toLowerCase().includes('mag'));
    }
    if (this.layout.xaxis != undefined) {
      this.layout.xaxis.type = this.selectedTimeUnit === 'iso' && this.isTimeView ? 'date' : 'linear';
    }
    this.fetchDataWithNewUnits();
  }

  //Debug buttons
  addDataClick(): void {
    // const data: [Partial<Plotly.PlotData>] = JSON.parse(JSON.stringify(dataFile.data));
    // const layout: Partial<Plotly.Layout> = JSON.parse(JSON.stringify(dataFile.layout));
    // this.readResponse(data, layout);
    this.data = [];
  }

  fetchGaiaDataClick(): void {
    // this.fetchData('Gaia-DR3', 'Gaia DR3 4111834567779557376');
    // this.fetchData('Gaia-DR3', 'Gaia DR3 93499857486642304');
    this.fetchData('Gaia-DR3', 'Gaia DR3 6606077966638809216');
  }
  fetchJwstDataClick(): void {
    this.fetchData('JWST-MID-IR', 'jw02783-o002_t001_miri_p750l-slitlessprism');
    // this.fetchData('XMM-EPIC', '103053615010005');
    // this.fetchData('XMM-EPIC', '103053605010008');
  }
}
