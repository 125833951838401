<plotly-plot
  divId="timeviz-plotly"
  [useResizeHandler]="true"
  [data]="data"
  [layout]="layout"
  [config]="config"
></plotly-plot>
<div id="bottom-left-button-container">
  <!--    <button (click)="addDataClick()">Data from file</button>-->
  <!--      <button (click)="fetchGaiaDataClick()">Fetch Gaia Data</button>-->
  <!--      <button (click)="fetchJwstDataClick()">Fetch JWST Data</button>-->
  <mat-form-field subscriptSizing="dynamic" class="select-form">
    <mat-label>Flux unit</mat-label>
    <mat-select
      [(ngModel)]="selectedFluxUnit"
      name="fluxUnit"
      class="select-unit"
      (selectionChange)="fluxUnitChanged($event.value)"
    >
      @for (fluxUnit of fluxUnits; track fluxUnit) {
      <mat-option [value]="fluxUnit.value">{{ fluxUnit.viewValue }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</div>
<div id="bottom-button-right-container">
  <mat-form-field subscriptSizing="dynamic" class="select-form" *ngIf="isTimeView">
    <mat-label>Time unit</mat-label>
    <mat-select
      [(ngModel)]="selectedTimeUnit"
      name="timeUnit"
      class="select-unit"
      (selectionChange)="timeUnitChanged($event.value)"
    >
      @for (timeUnit of timeUnits; track timeUnit) {
      <mat-option [value]="timeUnit.value">{{ timeUnit.viewValue }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
  <button (click)="switchWavelengthTimeView()">
    <img src="https://sky.esa.int/timeviz/assets/images/wavelength_time.png" alt="Wavelength / Time" class="icon" />
  </button>
</div>
<mat-spinner *ngIf="isLoading" class="loading-spinner"></mat-spinner>
